import React from 'react'

const Article: React.FC = props => {
  return (
    <article
      className="min-h-screen max-w-prose flex flex-col justify-center items-center py-14 md:py-24 lg:py-40 mx-auto"
      {...props}
    />
  )
}

export default Article
