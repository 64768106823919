import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from 'src/components/Layout'
import Seo from 'src/components/Seo'
import Article from 'src/components/Article'

const Index: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('philosophy')} />
      <Article>
        <div
          className="prose max-w-prose"
          dangerouslySetInnerHTML={{ __html: t('philosophyBody') || '' }}
        />
      </Article>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
